import { Route, Routes } from 'react-router-dom';

import { useRoutePaths } from 'hooks';
import { PrivateRoute } from '../PrivateRoute';
import { PublicRoute } from '../PublicRoute';
import Auth from 'pages/auth/auth';
import Home from 'pages/home/home';
import UserListPage from 'pages/users';
import EventListPage from 'pages/events/list';
import ModifyEventPage from 'pages/events/modify';
import RankingPage from 'pages/ranking';
import EventDetails from 'pages/events/details';
import PageEditor from 'pages/page-editor';
import Notification from 'pages/notification';
import NotificationAdd from 'pages/notification/add';
import TicketEventListPage from 'pages/ticket_delivery/list';
import TicketEventDetails from 'pages/ticket_delivery/details';
import UserDetails from 'pages/users/Details';
import ModifyUser from 'pages/users/Modify';
import { TranslatePage } from 'pages/translate';
import { RoleType } from '@core';

function Router() {
  const {
    SIGNIN_PATH,
    REGISTER_PATH,
    DASHBOARD_PATH,
    ROOT_PATH,
    USER_LIST_PATH,
    EVENTS_LIST_PATH,
    EVENTS_MODIFY_PATH,
    USER_DETAILS_PATH,
    RANKING_LIST_PATH,
    EVENTS_ADD_PATH,
    EVENTS_DETAILS_PATH,
    TICKET_DETAILS_PATH,
    USER_ADD_PATH,
    USER_MODIFY_PATH,
    PAGE_EDITOR_PATH,
    NOTIFICATION_LIST_PATH,
    TICKET_LIST_PATH,
    NOTIFICATION_ADD_PATH,
    TRANSLATE_EDITOR_PATH,
  } = useRoutePaths();

  return (
    <Routes>
      <Route path={ROOT_PATH}>
        <Route
          element={
            <PrivateRoute
              roles={[RoleType.TICKET_MANAGER, RoleType.MODERATOR, RoleType.EVENT_MANAGER]}
              redirectTo={SIGNIN_PATH}
            >
              <></>
            </PrivateRoute>
          }
          index
        />
        <Route
          path={DASHBOARD_PATH}
          element={
            <PrivateRoute roles={[RoleType.MODERATOR]} redirectTo={SIGNIN_PATH}>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path={TICKET_LIST_PATH}
          element={
            <PrivateRoute roles={[RoleType.TICKET_MANAGER]} redirectTo={SIGNIN_PATH}>
              <TicketEventListPage />
            </PrivateRoute>
          }
        />
        <Route
          path={USER_LIST_PATH}
          element={
            <PrivateRoute roles={[RoleType.MODERATOR]} redirectTo={SIGNIN_PATH}>
              <UserListPage />
            </PrivateRoute>
          }
        />
        <Route
          path={RANKING_LIST_PATH}
          element={
            <PrivateRoute roles={[RoleType.MODERATOR]} redirectTo={SIGNIN_PATH}>
              <RankingPage />
            </PrivateRoute>
          }
        />
        <Route
          path={USER_ADD_PATH}
          element={
            <PrivateRoute roles={[RoleType.MODERATOR]} redirectTo={SIGNIN_PATH}>
              <ModifyUser />
            </PrivateRoute>
          }
        />
        <Route
          path={USER_MODIFY_PATH}
          element={
            <PrivateRoute roles={[RoleType.MODERATOR]} redirectTo={SIGNIN_PATH}>
              <ModifyUser />
            </PrivateRoute>
          }
        />
        <Route
          path={EVENTS_ADD_PATH}
          element={
            <PrivateRoute roles={[RoleType.EVENT_MANAGER]} redirectTo={SIGNIN_PATH}>
              <ModifyEventPage />
            </PrivateRoute>
          }
        />
        <Route
          path={TRANSLATE_EDITOR_PATH}
          element={
            <PrivateRoute roles={[RoleType.MODERATOR]} redirectTo={SIGNIN_PATH}>
              <TranslatePage />
            </PrivateRoute>
          }
        />
        <Route
          path={USER_DETAILS_PATH}
          element={
            <PrivateRoute roles={[RoleType.MODERATOR]} redirectTo={SIGNIN_PATH}>
              <UserDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={EVENTS_DETAILS_PATH}
          element={
            <PrivateRoute roles={[RoleType.EVENT_MANAGER]} redirectTo={SIGNIN_PATH}>
              <EventDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={TICKET_DETAILS_PATH}
          element={
            <PrivateRoute roles={[RoleType.TICKET_MANAGER]} redirectTo={SIGNIN_PATH}>
              <TicketEventDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={EVENTS_MODIFY_PATH}
          element={
            <PrivateRoute roles={[RoleType.EVENT_MANAGER]} redirectTo={SIGNIN_PATH}>
              <ModifyEventPage />
            </PrivateRoute>
          }
        />
        <Route
          path={EVENTS_LIST_PATH}
          element={
            <PrivateRoute roles={[RoleType.EVENT_MANAGER]} redirectTo={SIGNIN_PATH}>
              <EventListPage />
            </PrivateRoute>
          }
        />
        <Route
          path={PAGE_EDITOR_PATH}
          element={
            <PrivateRoute roles={[RoleType.MODERATOR]} redirectTo={SIGNIN_PATH}>
              <PageEditor />
            </PrivateRoute>
          }
        />
        <Route
          path={NOTIFICATION_LIST_PATH}
          element={
            <PrivateRoute roles={[RoleType.EVENT_MANAGER]} redirectTo={SIGNIN_PATH}>
              <Notification />
            </PrivateRoute>
          }
        />
        <Route
          path={NOTIFICATION_ADD_PATH}
          element={
            <PrivateRoute roles={[RoleType.EVENT_MANAGER]} redirectTo={SIGNIN_PATH}>
              <NotificationAdd />
            </PrivateRoute>
          }
        />
      </Route>

      <Route
        path={SIGNIN_PATH}
        element={
          <PublicRoute>
            <Auth />
          </PublicRoute>
        }
      />

      <Route path={REGISTER_PATH} element={<Auth />} />

      <Route path="*" element={<h1>404</h1>} />
    </Routes>
  );
}

export default Router;
