import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import cn from 'classnames';

import styles from './page-editor.module.sass';
import { OverlayLoader } from 'components/Loader';
import Editor from 'components/Editor';
import { getPageContent, updatePage } from 'core/services/komagebi/page';
import { getErrorMessage } from '@core';
import Card from 'components/Card';

const schema = yup.object().shape({
  content: yup.string().required('სავალდებულოა'),
  content_en: yup.string().required('სავალდებულოა'),
});

const PageEditor = () => {
  const params = useParams();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ content: string; content_en: string }>({ resolver: yupResolver(schema) });
  const [isAdding, setIsAdding] = React.useState(false);

  const [defaultContentGeo, setDefaultContentGeo] = React.useState('');
  const [defaultContentEn, setDefaultContentEn] = React.useState('');

  const onSubmit = () => {
    void handleSubmit(
      data => {
        setIsAdding(true);

        updatePage(params.name, data)
          .then(() => {
            toast.success('წარმატებით განახლდა');
          })
          .catch(() => {
            toast.error('დაფისქირდა შეცდომა');
          })
          .finally(() => {
            setIsAdding(false);
          });
      },
      (errors, event) => {
        console.log(errors, event, 'validation errors');
      },
    )();
  };

  const handleContentChange = (value: string) => {
    setValue('content', value, { shouldValidate: true });
  };

  const handleContentChangeEn = (value: string) => {
    setValue('content_en', value, { shouldValidate: true });
  };

  useEffect(() => {
    if (params.name) {
      void getPageContent(params.name).then(response => {
        setDefaultContentGeo(response.data.content);
        setDefaultContentEn(response.data.content_en);
        handleContentChange(response.data.content);
        handleContentChangeEn(response.data.content_en);
      });
    }
  }, [params.name]);

  return (
    <>
      {isAdding && <OverlayLoader />}
      <>
        <Card className={cn(styles.card)} title="სტატიკური გვერდი" classTitle="title-green">
          <div className={styles.card}>
            <div className={styles.col}>
              <Editor
                classEditor={styles.editor}
                label="აღწერა"
                onChange={handleContentChange}
                defaultValue={defaultContentGeo}
                error={getErrorMessage(errors, 'content')}
              />
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.col}>
              <Editor
                classEditor={styles.editor}
                label="აღწერა - ინგლისური"
                onChange={handleContentChangeEn}
                defaultValue={defaultContentEn}
                error={getErrorMessage(errors, 'content_en')}
              />
            </div>
          </div>
          <div className={cn('panel', styles.panel)}>
            <div className={styles.info}></div>
            <div className={styles.btns}>
              <button onClick={onSubmit} className={cn('button', styles.button)}>
                შენახვა
              </button>
            </div>
          </div>
        </Card>
      </>
    </>
  );
};

export default PageEditor;
