import { IPaginatedResponse, IResponse, IUser, IUserListItem, IUserScoreLogListItem, UserCreatePayload } from '@types';
import { httpClient } from '../http-client';

export const getUserList = async ([page, search]: [number, string]): Promise<IPaginatedResponse<IUserListItem>> => {
  const response = await httpClient.get<IPaginatedResponse<IUserListItem>>('user/list', {
    params: {
      search,
      page: page,
      limit: 10,
    },
  });
  return response.data;
};

export const getUserDetails = async (id: string): Promise<IResponse<IUserListItem>> => {
  const response = await httpClient.get<IResponse<IUserListItem>>(`user/${id}/details`);
  return response.data;
};

export const getRegisteredReports = async (): Promise<{ month: string; count: number }[]> => {
  const response = await httpClient.get<IResponse<{ month: string; count: number }[]>>(`user/registered_reports`);
  return response.data.data.map(item => {
    return {
      ...item,
      count: Number(item.count),
    };
  });
};

export const exportUsersToExcel = async (search: string): Promise<void> => {
  try {
    const response = await httpClient.get('user/list/export', {
      params: {
        search,
      },
      responseType: 'blob',
    });

    // Create a link element
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    // Set the file name
    link.setAttribute('download', 'users.xlsx');

    // Append to the document and trigger click
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading the Excel file:', error);
  }
};

export const getSummaryReports = async (): Promise<
  IResponse<{ total_count: number; total_count_attendee: number; current_month_count: number; recent_users: IUser }>
> => {
  const response =
    await httpClient.get<
      IResponse<{ total_count: number; total_count_attendee: number; current_month_count: number; recent_users: IUser }>
    >(`user/summary_reports`);
  return response.data;
};

export const updateUserDetails = async (
  id: string,
  body: UserCreatePayload,
): Promise<IResponse<{ user: IUserListItem }>> => {
  const response = await httpClient.put<IResponse<{ user: IUserListItem }>>(`user/${id}/update`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const createUser = async (body: UserCreatePayload): Promise<IResponse<{ user: IUserListItem }>> => {
  const response = await httpClient.post<IResponse<{ user: IUserListItem }>>(`user/create`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const getUserScoreLogs = async ({ id }, { signal }): Promise<IResponse<IUserScoreLogListItem[]>> => {
  const response = await httpClient.get<IResponse<IUserScoreLogListItem[]>>(`user/${id}/score_logs`);
  return response.data;
};

export const deactivateUser = async (id: string): Promise<IResponse<boolean>> => {
  const response = await httpClient.get<IResponse<boolean>>(`user/${id}/deactivate`);
  return response.data;
};

export const activateUser = async (id: string): Promise<IResponse<boolean>> => {
  const response = await httpClient.get<IResponse<boolean>>(`user/${id}/activate`);
  return response.data;
};

export const filterUserByName = async (query: string): Promise<IResponse<IUserListItem[]>> => {
  const response = await httpClient.get<IResponse<IUserListItem[]>>('user/filter', {
    params: {
      query: query,
    },
  });
  return response.data;
};

export const updateUserScore = async (body: {
  userId: string;
  score: number;
  description: string;
}): Promise<IResponse<boolean>> => {
  const response = await httpClient.put<IResponse<boolean>>(`user/update_score`, body);

  return response.data;
};

export const updateUserRole = async (body: { userId: string; role: string }): Promise<IResponse<boolean>> => {
  const response = await httpClient.put<IResponse<boolean>>(`user/update_role`, body);

  return response.data;
};
