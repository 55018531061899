import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { useAppSelector } from 'store';
import { accountSelectors } from 'store/account';
import { Loader } from 'components/Loader';
import { ErrorState } from 'components/ErrorState';
import Page from 'components/Page';
import { validateUserPermissions } from 'core/utils/validateUserPermissions';
import { RoleType } from '@core';

type Props = {
  roles: RoleType[];
  redirectTo?: string;
  children: React.ReactNode;
};

const PrivateRoute: React.FC<Props> = props => {
  const { redirectTo = '/login' } = props;

  const user = useAppSelector(accountSelectors.userSelector);
  const userLoading = useAppSelector(accountSelectors.userLoadingSelector);

  const isAuthenticated = user !== undefined;

  const isAuthorized = user ? validateUserPermissions(user, props.roles) : false;
  console.log(user, isAuthorized, 'user');

  if (userLoading) {
    return <Loader />;
  }

  if (!isAuthenticated || !isAuthorized) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <ErrorBoundary fallback={<ErrorState text="An error occurred in the application." />}>
      <Suspense fallback={<Loader />}>
        <Page>{props.children}</Page>
      </Suspense>
    </ErrorBoundary>
  );
};

export default PrivateRoute;
