import React from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './Head.module.sass';
import Icon from '../../../../components/Icon';
import { IUserListItem } from '@types';
import { paths } from 'router/paths';
import Actions from 'components/Actions';
import { RoleType } from '@core';
import { useAppSelector } from 'store';
import { accountSelectors } from 'store/account';

type HeadProps = {
  className?: string;
  details: IUserListItem;
  openUpdateScoreModal: () => void;
  openUpdateRoleModal: () => void;
  onDeactivate: () => void;
  onActivate: () => void;
};

const Head: React.FC<HeadProps> = ({
  className,
  details,
  openUpdateScoreModal,
  openUpdateRoleModal,
  onActivate,
  onDeactivate,
}) => {
  const authorizedUser = useAppSelector(accountSelectors.userSelector);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToDetails = (id: string) => {
    navigate(paths.USER_MODIFY_PATH.replace(':id', id));
  };

  return (
    <div className={cn(styles.head, className)}>
      <div className={styles.user}>
        <div className={styles.avatar}>
          <img src={details.profilePhoto} alt="Avatar" />
        </div>
        <div className={styles.details}>
          <div className={styles.man}>
            {details.firstName} {details.lastName}
          </div>
          <div className={styles.login}>{details.email}</div>
        </div>
        <div
          className={cn(
            {
              'status-red': details.role === RoleType.SUPERADMIN || details.role === RoleType.MODERATOR,
              'status-green': details.role === RoleType.USER,
              'status-purple': details.role === RoleType.EVENT_MANAGER,
              'status-blue': details.role === RoleType.TICKET_MANAGER,
            },
            styles.status,
          )}
        >
          {t(details.role)}
        </div>
      </div>
      <div className={styles.btns}>
        <Actions className={styles.actions} classActionsHead={styles.actionsHead} classActionsBody={styles.actionsBody}>
          <div>
            <button
              onClick={() => navigateToDetails(details.id)}
              className={cn('button-small status-green', styles.button)}
            >
              <span>რედაქტირება</span>
              <Icon name="edit" size="24" />
            </button>

            {authorizedUser?.role === RoleType.SUPERADMIN && (
              <>
                <button onClick={() => openUpdateScoreModal()} className={cn('button-small', styles.button)}>
                  <span>ქულის ცვლილება</span>
                  <Icon name="edit" size="24" />
                </button>

                <button onClick={() => openUpdateRoleModal()} className={cn('button-small', styles.button)}>
                  <span>როლის ცვლილება</span>
                  <Icon name="edit" size="24" />
                </button>
                {!details.deletedAt && (
                  <button onClick={() => onDeactivate()} className={cn('button-small status-red', styles.button)}>
                    <span>დეაქტივაცია</span>
                  </button>
                )}

                {details.deletedAt && (
                  <button onClick={() => onActivate()} className={cn('button-small status-green', styles.button)}>
                    <span>აქტივაცია</span>
                  </button>
                )}
              </>
            )}
          </div>
        </Actions>
      </div>
    </div>
  );
};

export default Head;
