import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import styles from './Details.module.sass';
import Icon from '../../../components/Icon';
import Head from './Head';
import { IUserListItem } from '@types';
import Parameter from './Parameter';
import { activateUser, deactivateUser, getUserDetails, RoleType } from '@core';
import Modal from 'components/Modal';
import UpdateScore from './UpdateScore';
import { TicketRequestList } from './TicketRequestList';
import { ScoreHistory } from './ScoreHistory';

import { toast } from 'react-toastify';
import UpdateRole from './UpdateRole';

const navigation = ['ბილეთები', 'ქულების ცვლილებები'];

const UserDetails = () => {
  const params = useParams();

  const [user, setUser] = useState<IUserListItem>();
  const [visibleScoreUpdateModal, setVisibleScoreUpdateModal] = useState(false);
  const [visibleRoleChangeModal, setVisibleRoleChangeModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (params.id) {
      void getUserDetails(params.id).then(response => {
        setUser(response.data);
      });
    }
  }, [params.id]);

  const formatedBirthday = moment(user?.birthday).format('DD/MM/YYYY');

  const handleOpenUpdateScoreModal = () => {
    setVisibleScoreUpdateModal(true);
  };

  const handleDeactivate = () => {
    const confirmed = window.confirm('ნამდვილად გსურთ დეაქტივაცია?');
    if (!confirmed) {
      return;
    }

    void deactivateUser(user?.id)
      .then(() => {
        toast.success('მომხმარებელი დეაქტივირებულია');
        void getUserDetails(params.id).then(response => {
          setUser(response.data);
        });
      })
      .catch(err => {
        console.log(err);
        toast.error('დაფიქსირდა შეცდომა');
      });
  };

  const handleActivate = () => {
    const confirmed = window.confirm('ნამდვილად გსურთ აქტივაცია?');
    if (!confirmed) {
      return;
    }

    void activateUser(user?.id)
      .then(() => {
        toast.success('მომხმარებელი გააქტიურებულია');
        void getUserDetails(params.id).then(response => {
          setUser(response.data);
        });
      })
      .catch(err => {
        console.log(err);
        toast.error('დაფიქსირდა შეცდომა');
      });
  };

  const handleOpenUpdateRoleModal = () => {
    setVisibleRoleChangeModal(true);
  };

  return (
    <>
      <Modal
        outerClassName={styles.outer}
        visible={visibleScoreUpdateModal}
        onClose={() => setVisibleScoreUpdateModal(false)}
      >
        <UpdateScore user={user} success={() => setVisibleScoreUpdateModal(false)} />
      </Modal>
      <Modal
        outerClassName={styles.outer}
        visible={visibleRoleChangeModal}
        onClose={() => setVisibleRoleChangeModal(false)}
      >
        <UpdateRole user={user} success={() => setVisibleRoleChangeModal(false)} />
      </Modal>
      {user && (
        <div className={cn(styles.details)}>
          <Head
            className={styles.head}
            onActivate={handleActivate}
            onDeactivate={handleDeactivate}
            details={user}
            openUpdateScoreModal={handleOpenUpdateScoreModal}
            openUpdateRoleModal={handleOpenUpdateRoleModal}
          />

          <div className={styles.group}>
            <Parameter label="ქულა" value={user.score} />
            <Parameter label="მეილი" value={user.email} />
            <Parameter label="ქალაქი" value={user.city} />
            <Parameter label="დაბადების თარიღი" value={formatedBirthday} />
            <Parameter label="ნომერი" value={user.phoneNumber} />
          </div>

          <br />
          <div className={styles.control}>
            <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
          </div>
          <div className={styles.wrap}>
            {activeIndex === 0 && (
              <>
                <TicketRequestList />
              </>
            )}

            {activeIndex === 1 && (
              <>
                <ScoreHistory />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetails;
