const ROOT_PATH = '/';
const DASHBOARD_PATH = '/dashboard';
const SIGNIN_PATH = '/login';
const REGISTER_PATH = '/register';
const USER_LIST_PATH = '/users';
const USER_DETAILS_PATH = '/users/:id';
const USER_ADD_PATH = '/users/add';
const USER_MODIFY_PATH = '/users/modify/:id';
const TICKET_LIST_PATH = '/tickets';
const TICKET_DETAILS_PATH = '/tickets/details/:id';
const RANKING_LIST_PATH = '/ranking';
const EVENTS_LIST_PATH = '/events';
const EVENTS_ADD_PATH = '/events/add';
const EVENTS_MODIFY_PATH = '/events/modify/:id';
const EVENTS_DETAILS_PATH = '/events/details/:id';
const PAGE_EDITOR_PATH = '/page-editor/:name';
const NOTIFICATION_LIST_PATH = '/notifications';
const NOTIFICATION_ADD_PATH = '/notifications/add';
const TRANSLATE_EDITOR_PATH = '/translate/:lang';

const paths = {
  ROOT_PATH,
  DASHBOARD_PATH,
  SIGNIN_PATH,
  USER_ADD_PATH,
  USER_MODIFY_PATH,
  REGISTER_PATH,
  USER_DETAILS_PATH,
  TICKET_LIST_PATH,
  USER_LIST_PATH,
  EVENTS_LIST_PATH,
  RANKING_LIST_PATH,
  EVENTS_ADD_PATH,
  EVENTS_MODIFY_PATH,
  EVENTS_DETAILS_PATH,
  TRANSLATE_EDITOR_PATH,
  TICKET_DETAILS_PATH,
  PAGE_EDITOR_PATH,
  NOTIFICATION_LIST_PATH,
  NOTIFICATION_ADD_PATH,
} as const;

export default paths;
