import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import styles from './modify.module.sass';
import Icon from 'components/Icon';
import { IUserListItem } from '@types';
import { getEnumValues, getErrorMessage, RoleType, updateUserRole, updateUserScore } from '@core';
import { OverlayLoader } from 'components/Loader';
import Dropdown from 'components/Dropdown';

const schema = yup.object().shape({
  userId: yup.string().required('სავალდებულოა'),
  role: yup.string().required('სავალდებულოა'),
});

const UpdateRole: React.FC<{
  user: IUserListItem | undefined;
  success: () => void;
}> = ({ user, success }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<{ role: string; userId: string }>({ resolver: yupResolver(schema) });

  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (user?.id) {
      setValue('userId', user.id);
      setValue('role', user.role, { shouldValidate: true });
    }
  }, [user, setValue]);

  const onSubmit = () => {
    void handleSubmit(
      data => {
        setIsAdding(true);

        updateUserRole(data)
          .then(() => {
            toast.success('განახლდა წარმატებით');
            user.role = data.role as RoleType;
            success();
          })
          .catch((err: AxiosError) => {
            toast.error('დაფისქირდა შეცდომა');
          })
          .finally(() => {
            setIsAdding(false);
          });
      },
      (err, event) => {
        console.log(err, event, 'validation errors');
      },
    )();
  };

  const handleValueChange = (name: string, value: string) => {
    setValue(name, value, { shouldValidate: true });
  };

  return (
    <div className={styles.post}>
      {isAdding && <OverlayLoader />}
      <div className={cn('title-green', styles.title)}>როლის ცვლილება</div>

      <div className={styles.col}>
        <Dropdown
          className={styles.field}
          label="როლი"
          value={getValues('role')}
          setValue={(value: string) => handleValueChange('role', value)}
          options={getEnumValues(RoleType)}
        />
      </div>

      <div className={styles.foot}>
        <button onClick={onSubmit} className={cn('button', styles.button)}>
          <span>დადასტურება</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
    </div>
  );
};

export default UpdateRole;
