import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import styles from './modify.module.sass';
import Icon from 'components/Icon';
import TextInput from 'components/TextInput';
import { RankBaseDto, RankDto } from '@types';
import { createRank, getErrorMessage, updateRank } from '@core';
import { OverlayLoader } from 'components/Loader';

const schema = yup.object().shape({
  name: yup.string().required('სავალდებულოა'),
  name_en: yup.string().required('სავალდებულოა'),
  minScore: yup.number().min(0).required('სავალდებულოა'),
  maxScore: yup.number().min(0).required('სავალდებულოა'),
});

type FormTypes = 'add' | 'edit';

const ModifyRank: React.FC<{ item?: RankDto; updated: () => void }> = ({ item, updated }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<RankBaseDto>({ resolver: yupResolver(schema) });

  const [formType, setFormType] = useState<FormTypes>('add');
  const [isAdding, setIsAdding] = useState(false);

  const onSubmit = () => {
    void handleSubmit(
      data => {
        console.log(data, 'req data');
        setIsAdding(true);

        const promise = formType === 'edit' ? updateRank(item?.id, data) : createRank(data);

        promise
          .then(() => {
            toast.success('წარმატებით განახლდა');
            updated();
          })
          .catch((err: AxiosError) => {
            const result = err.response?.data as { statusCode: number; message: string[] };
            console.log(result);
            if (result && result.statusCode === 406 && result.message.indexOf('error.alreadyExists') !== -1) {
              toast.error('სახელი უკვე არსებობს');
              return;
            }
            toast.error('დაფისქირდა შეცდომა');
          })
          .finally(() => {
            setIsAdding(false);
          });
      },
      (err, event) => {
        console.log(err, event, 'validation errors');
      },
    )();
  };

  useEffect(() => {
    if (item) {
      reset({
        name: item.name,
        name_en: item.name_en,
        minScore: item.minScore,
        maxScore: item.maxScore,
      });
      setFormType('edit');
    } else {
      setFormType('add');
    }
  }, [item]);

  const text = formType === 'add' ? 'დამატება' : 'რედაქტირება';

  return (
    <div className={styles.post}>
      {isAdding && <OverlayLoader />}
      <div className={cn('title-green', styles.title)}>რანკის {text}</div>

      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="სახელი"
          name="name"
          error={getErrorMessage(errors, 'name')}
          type="text"
          register={register}
        />
      </div>

      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="სახელი - ინგლისური"
          name="name_en"
          error={getErrorMessage(errors, 'name_en')}
          type="text"
          register={register}
        />
      </div>

      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="მინიმალური ქულა"
          name="minScore"
          error={getErrorMessage(errors, 'minScore')}
          type="number"
          register={register}
        />
      </div>

      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="მაქსიმალური ქულა"
          name="maxScore"
          error={getErrorMessage(errors, 'maxScore')}
          type="number"
          register={register}
        />
      </div>

      <div className={styles.foot}>
        <button onClick={onSubmit} className={cn('button', styles.button)}>
          <span>{text}</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
    </div>
  );
};

export default ModifyRank;
