import React, { useState } from 'react';
import { useAsync } from 'react-async';
import cn from 'classnames';

import styles from './Overview.module.sass';
import Item from './Item';
import Card from '../../../components/Card';
import Dropdown from '../../../components/Dropdown';
import Users from './Users';
import Chart from './Chart';
import { getSummaryReports, getUniqueCount } from '@core';

const intervals = ['All time', 'In a year', 'Per month'];

const Overview = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [activeIndex, setActiveIndex] = useState(0);

  const { data, isLoading } = useAsync(getSummaryReports);
  const { data: uniqueCountData } = useAsync(getUniqueCount);

  return (
    <Card className={cn(styles.card, className)} title="ზოგადი ინფორმაცია" classTitle="title-red">
      {isLoading && <div>Loading...</div>}
      {data && (
        <div className={styles.overview}>
          <div className={styles.nav}>
            <Item
              className={cn(styles.item, {
                [styles.active]: 0 === activeIndex,
              })}
              onActive={() => setActiveIndex(0)}
              item={{
                title: 'მომხმარებლები',
                counter: data.data.total_count,
                icon: 'profile-circle',
                color: '#B1E5FC',
                value: data.data.current_month_count,
              }}
            />
            {uniqueCountData && (
              <Item
                className={cn(styles.item, {
                  [styles.active]: 0 === activeIndex,
                })}
                onActive={() => setActiveIndex(0)}
                item={{
                  title: 'მინიმუმ ერთ ივენთზე ნამყოფი მოხმარებლები',
                  counter: uniqueCountData.data,
                  icon: 'profile-circle',
                  color: '#B1E5FC',
                }}
              />
            )}
          </div>

          <div className={styles.body}>{activeIndex === 0 && data && <Users users={data.data.recent_users} />}</div>
        </div>
      )}
    </Card>
  );
};

export default Overview;
