import React, { useEffect } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import styles from './GeneralInformation.module.sass';
import Card from 'components/Card';
import Icon from 'components/Icon';
import TextInput from 'components/TextInput';
import Editor from 'components/Editor';
import File from 'components/File';
import { paths } from 'router/paths';
import { getErrorMessage } from '@core';

interface GeneralInformationProps {
  className?: string;
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({ className }) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [defaultContentGeo, setDefaultContentGeo] = React.useState('');
  const [defaultContentEn, setDefaultContentEn] = React.useState('');

  const [imageControlValue, setImageControlValue] = React.useState();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === undefined) {
        setDefaultContentGeo(value.description);
        setDefaultContentEn(value.description_en);
      }
      handleImageChange(value.image);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleContentChangeGeo = (value: string) => {
    setValue('description', value, { shouldValidate: true });
  };

  const handleContentChangeEn = (value: string) => {
    setValue('description_en', value, { shouldValidate: true });
  };

  const handleImageChange = (value: any) => {
    if (value?.[0]?.name) {
      const currentFile = value?.[0];
      if (currentFile) {
        setImageControlValue(URL.createObjectURL(currentFile));
      }
    } else if (typeof value === 'string') {
      setImageControlValue(value);
    }
  };

  const updateDate = (date: Date, name: string) => {
    setValue(name, date, { shouldValidate: true });
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title="ზოგადი ინფორმაცია"
      classTitle="title-green"
      head={
        <Link className={cn('button-stroke button-small', styles.button)} to={paths.EVENTS_LIST_PATH}>
          <Icon name="arrow-left" size="24" />
          <span>უკან</span>
        </Link>
      }
    >
      <div className={styles.description}>
        <File
          error={getErrorMessage(errors, 'image')}
          className={styles.field}
          name="image"
          title="ფოტოს ატვირთვა"
          type="image"
          label="ფოტო"
          value={imageControlValue}
          register={register}
        />
        <TextInput
          className={styles.field}
          label="სახელი"
          name="title"
          error={getErrorMessage(errors, 'title')}
          type="text"
          register={register}
        />

        <TextInput
          className={styles.field}
          label="სახელი - ინგლისური"
          name="title_en"
          error={getErrorMessage(errors, 'title_en')}
          type="text"
          register={register}
        />

        <Editor
          classEditor={styles.editor}
          label="აღწერა"
          onChange={handleContentChangeGeo}
          defaultValue={defaultContentGeo}
          error={getErrorMessage(errors, 'description')}
        />

        <Editor
          classEditor={styles.editor}
          label="აღწერა - ინგლისური"
          onChange={handleContentChangeEn}
          defaultValue={defaultContentEn}
          error={getErrorMessage(errors, 'description_en')}
        />

        <TextInput
          className={styles.field}
          label="ლოკაციის სახელი"
          name="locationName"
          error={getErrorMessage(errors, 'locationName')}
          type="text"
          register={register}
        />

        <TextInput
          className={styles.field}
          label="ლოკაციის სახელი - ინგლისური"
          name="locationName_en"
          error={getErrorMessage(errors, 'locationName_en')}
          type="text"
          register={register}
        />
      </div>

      <div className={cn(styles.wrapper)}>
        <div className={cn(styles.date)}>
          <div className={cn(styles.label)}>
            თარიღი
            {getErrorMessage(errors, 'date') && (
              <span className={cn(styles.error)}>- {getErrorMessage(errors, 'date')}</span>
            )}
          </div>

          <DatePicker
            selected={getValues('date')}
            showTimeSelect
            locale="ka"
            timeFormat="HH:mm"
            dateFormat={'dd/M/yyyy HH:mm'}
            onChange={date => updateDate(date, 'date')}
          />
        </div>
        <div className={cn(styles.date)}>
          <div className={cn(styles.label)}>
            ქულა
            {getErrorMessage(errors, 'attendantScore') && (
              <span className={cn(styles.error)}>- {getErrorMessage(errors, 'attendantScore')}</span>
            )}
          </div>
          <TextInput
            className={styles.field}
            name="attendantScore"
            error={getErrorMessage(errors, 'attendantScore')}
            type="number"
            register={register}
          />
        </div>
      </div>

      <div className={cn(styles.wrapper)}>
        <div className={cn(styles.date)}>
          <div className={cn(styles.label)}>
            ბილეთის მოთხოვნის დაწყება
            {getErrorMessage(errors, 'joinRequestStartDate') && (
              <span className={cn(styles.error)}>- {getErrorMessage(errors, 'joinRequestStartDate')}</span>
            )}
          </div>
          <DatePicker
            selected={getValues('joinRequestStartDate')}
            onChange={date => updateDate(date, 'joinRequestStartDate')}
            showTimeSelect
            locale="ka"
            timeFormat="HH:mm"
            dateFormat={'dd/M/yyyy HH:mm'}
          />
        </div>

        <div className={cn(styles.date)}>
          <div className={cn(styles.label)}>
            ბილეთის მოთხოვნის ბოლო
            {getErrorMessage(errors, 'joinRequestEndDate') && (
              <span className={cn(styles.error)}>- {getErrorMessage(errors, 'joinRequestEndDate')}</span>
            )}
          </div>
          <DatePicker
            selected={getValues('joinRequestEndDate')}
            onChange={date => updateDate(date, 'joinRequestEndDate')}
            showTimeSelect
            locale="ka"
            timeFormat="HH:mm"
            dateFormat={'dd/M/yyyy HH:mm'}
          />
        </div>

        <div className={cn(styles.date)}>
          <div className={cn(styles.label)}>
            ბილეთის მიცემის ბოლო
            {getErrorMessage(errors, 'ticketHandoutDate') && (
              <span className={cn(styles.error)}>- {getErrorMessage(errors, 'ticketHandoutDate')}</span>
            )}
          </div>
          <DatePicker
            selected={getValues('ticketHandoutDate')}
            onChange={date => updateDate(date, 'ticketHandoutDate')}
            showTimeSelect
            locale="ka"
            timeFormat="HH:mm"
            dateFormat={'dd/M/yyyy HH:mm'}
          />
        </div>

        <div className={cn(styles.date)}>
          <div className={cn(styles.label)}>
            ბილეთების რაოდენობა
            {getErrorMessage(errors, 'availableTicketsCount') && (
              <span className={cn(styles.error)}>- {getErrorMessage(errors, 'availableTicketsCount')}</span>
            )}
          </div>
          <TextInput
            className={styles.field}
            name="availableTicketsCount"
            error={getErrorMessage(errors, 'availableTicketsCount')}
            type="number"
            register={register}
          />
        </div>
      </div>
    </Card>
  );
};

export default GeneralInformation;
