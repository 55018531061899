import { IUser } from '@types';
import { RoleHierarchy, RoleType } from '../enums';

function getEffectiveRoles(role: RoleType): RoleType[] {
  const roles = RoleHierarchy.get(role);
  if (!role || !roles) {
    return [];
  }
  return roles;
}

export function validateUserPermissions(user: IUser, requiredRoles: RoleType[]) {
  const userEffectiveRoles = getEffectiveRoles(user.role);

  return requiredRoles.some(requiredRole => userEffectiveRoles.includes(requiredRole));
}
