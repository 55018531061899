export enum RoleType {
  USER = 'USER',
  TICKET_MANAGER = 'TICKET_MANAGER',
  EVENT_MANAGER = 'EVENT_MANAGER',
  SUPERADMIN = 'SUPERADMIN',
  MODERATOR = 'MODERATOR',
}

export const RoleHierarchy = new Map<RoleType, RoleType[]>([
  [
    RoleType.SUPERADMIN,
    [RoleType.USER, RoleType.EVENT_MANAGER, RoleType.TICKET_MANAGER, RoleType.SUPERADMIN, RoleType.MODERATOR],
  ],
  [RoleType.MODERATOR, [RoleType.USER, RoleType.EVENT_MANAGER, RoleType.TICKET_MANAGER, RoleType.MODERATOR]],

  [RoleType.EVENT_MANAGER, [RoleType.EVENT_MANAGER, RoleType.USER]],
  [RoleType.TICKET_MANAGER, [RoleType.TICKET_MANAGER, RoleType.USER]],
  [RoleType.USER, [RoleType.USER]],
]);
