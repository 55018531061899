import React from 'react';
import cn from 'classnames';

import styles from './Table.module.sass';
import { Loader } from '../../../components/Loader';
import Row from './Row';

// data
import { IPaginatedResponse, IUserListItem } from '@types';
import { Paginate } from 'components/Paginate';

interface TableProps {
  className?: string;
  isRequestLoading: boolean;
  users: IPaginatedResponse<IUserListItem> | undefined;
  pageCount: number;
  handlePageChange: (selectedItem: { selected: number }) => void;
}

const Table: React.FC<TableProps> = ({ className, users, isRequestLoading, pageCount, handlePageChange }) => {
  return (
    <>
      {isRequestLoading && <Loader />}
      <div className={cn(styles.wrapper, className)}>
        <div className={cn(styles.table)}>
          <div className={cn(styles.row)}>
            <div className={styles.col}></div>
            <div className={styles.col}>სახელი</div>
            <div className={styles.col}>მეილი</div>
            <div className={styles.col}>ტელ. ნომერი</div>
            <div className={styles.col}>სტატუსი</div>
            <div className={styles.col}>როლი</div>
          </div>

          {!isRequestLoading && users?.data?.items.map(user => <Row item={user} key={user.id} />)}
        </div>
        <Paginate pageCount={pageCount} handlePageClick={handlePageChange} />
      </div>
    </>
  );
};

export default Table;
