import {
  EventCreatePayload,
  IEventListItem,
  IEventTicketListItem,
  IPaginatedResponse,
  IResponse,
  IUserEvenTicketListItem,
} from '@types';
import { httpClient } from '../http-client';

export const getEventList = async ([page, search]): Promise<IPaginatedResponse<IEventListItem>> => {
  const response = await httpClient.get<IPaginatedResponse<IEventListItem>>('event/list', {
    params: {
      search,
      page: page,
      limit: 10,
    },
  });
  return response.data;
};

export const getEvent = async (id: string): Promise<IResponse<IEventListItem>> => {
  const response = await httpClient.get<IResponse<IEventListItem>>('event', {
    params: {
      id,
    },
  });
  return response.data;
};

export const createEvent = async (payload: EventCreatePayload): Promise<IEventListItem> => {
  const response = await httpClient.post<IEventListItem>('event/create', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

export const updateEvent = async (id: string, payload: EventCreatePayload): Promise<IEventListItem> => {
  const response = await httpClient.put<IEventListItem>(`event/${id}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

export const deleteEvent = async (id: string): Promise<IResponse<{ id: string; success: boolean }>> => {
  const response = await httpClient.delete<IResponse<{ id: string; success: boolean }>>('event/delete', {
    data: { id },
  });

  return response.data;
};

export const deliverTicket = async (id: string): Promise<IResponse<boolean>> => {
  const response = await httpClient.put<IResponse<boolean>>(`tickets/${id}/deliver`);

  return response.data;
};

export const getUniqueCount = async (): Promise<IResponse<number>> => {
  const response = await httpClient.get<IResponse<number>>(`tickets/count-unique`);
  return response.data;
};

export const getEvenTicketList = async ({ id }, { signal }): Promise<IPaginatedResponse<IEventTicketListItem>> => {
  const response = await httpClient.get<IPaginatedResponse<IEventTicketListItem>>(`tickets/${id}`, {
    params: {
      page: 1,
      limit: 10,
    },
  });
  return response.data;
};
export const getApprovedTicketList = async ({ id }, { signal }): Promise<IPaginatedResponse<IEventTicketListItem>> => {
  const response = await httpClient.get<IPaginatedResponse<IEventTicketListItem>>(`tickets/${id}/approved`, {
    params: {
      page: 1,
      limit: 10,
    },
  });
  return response.data;
};

export const getApprovedTicketListForUser = async (
  { id },
  { signal },
): Promise<IResponse<IUserEvenTicketListItem[]>> => {
  const response = await httpClient.get<IResponse<IUserEvenTicketListItem[]>>(`tickets/${id}/history`);
  return response.data;
};

export const exportTicketsToExcel = async (id: string): Promise<void> => {
  try {
    const response = await httpClient.get(`tickets/${id}/export_approved_tickets`, {
      responseType: 'blob',
    });

    // Create a link element
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    // Set the file name
    link.setAttribute('download', 'tickets.xlsx');

    // Append to the document and trigger click
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading the Excel file:', error);
  }
};

export const getTicketCount = async (
  { id },
  { signal },
): Promise<IResponse<{ approved: number; delivered: number }>> => {
  const response = await httpClient.get<IResponse<{ approved: number; delivered: number }>>(
    `tickets/${id}/ticket_counts`,
  );

  return response.data;
};

export const declineTicket = async (eventId: string, ticketId: string): Promise<IResponse<boolean>> => {
  const response = await httpClient.post<IResponse<boolean>>(`tickets/${eventId}/decline`, {
    ticketId,
  });

  return response.data;
};

export const approveTicket = async (
  eventId: string,
  body: { ticketId: string; quantity: number },
): Promise<IResponse<boolean>> => {
  const response = await httpClient.post<IResponse<boolean>>(`tickets/${eventId}/approve`, body);

  return response.data;
};

export const createTicket = async (
  eventId: string,
  body: { userId: string; quantity: number },
): Promise<IResponse<boolean>> => {
  const response = await httpClient.post<IResponse<boolean>>(`tickets/${eventId}/create`, body);

  return response.data;
};
