import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './Row.module.sass';
import { IUserListItem } from '@types';
import { paths } from 'router/paths';
import { RoleType } from '@core';

interface RowProps {
  item: IUserListItem;
}

const Row = ({ item }: RowProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToDetails = (id: string) => {
    navigate(paths.USER_DETAILS_PATH.replace(':id', id));
  };

  return (
    <>
      <div className={cn(styles.row)}>
        <div className={styles.col}></div>
        <div className={styles.col}>
          <div className={styles.item} onClick={() => navigateToDetails(item.id)}>
            <div className={styles.avatar}>
              <img
                src={item.profilePhoto !== '' ? item.profilePhoto : '/images/content/avatar-2.jpg'}
                alt="Profile Photo"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>{`${item.firstName} ${item.lastName}`}</div>
              <div className={styles.city}>{item.city}</div>
              <div className={styles.email}>{item.email}</div>
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.email}>{item.email}</div>
        </div>
        <div className={styles.col}>
          <div>{item.phoneNumber}</div>
        </div>
        <div className={styles.col}>
          {item.deletedAt && <div className={cn('status-red', styles.status)}>დეაქტივირებული</div>}

          {!item.deletedAt && <div className={cn('status-green', styles.status)}>აქტიური</div>}
        </div>
        <div className={styles.col}>
          <div
            className={cn(
              {
                'status-red': item.role === RoleType.SUPERADMIN || item.role === RoleType.MODERATOR,
                'status-green': item.role === RoleType.USER,
                'status-purple': item.role === RoleType.EVENT_MANAGER,
                'status-blue': item.role === RoleType.TICKET_MANAGER,
              },
              styles.status,
            )}
          >
            {t(item.role)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
