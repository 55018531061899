import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import cn from 'classnames';
import { SelectedOption, SelectedOptionValue, SelectSearchProps } from 'react-select-search';

import styles from './add.module.sass';
import { OverlayLoader } from 'components/Loader';
import Editor from 'components/Editor';
import { getErrorMessage } from '@core';
import Card from 'components/Card';
import TextInput from 'components/TextInput';
import Dropdown from 'components/Dropdown';
import { CreateNotificationPayload } from '@types';
import { createNotification } from 'core/services/komagebi/notification';
import { paths } from 'router/paths';
import { UserSelector } from 'components/UserSelector';

import { RankSelector } from 'components/RankSelector';

const schema = yup.object().shape({
  message: yup.string().required('სავალდებულოა'),
  description: yup.string().required('სავალდებულოა'),
  title: yup.string().required('სავალდებულოა'),
  type: yup.string().required('სავალდებულოა'),
  properties: yup.array().optional(),
});

const optionsType = ['all', 'rank', 'personal'];

const NotificationAdd = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useForm<CreateNotificationPayload>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: 'all',
    },
  });
  const [isAdding, setIsAdding] = React.useState(false);
  const onSubmit = () => {
    void handleSubmit(
      data => {
        setIsAdding(true);

        createNotification(data)
          .then(() => {
            navigate(paths.NOTIFICATION_LIST_PATH);
            toast.success('წარმატებით დაემატა');
          })
          .catch(() => {
            toast.error('დაფისქირდა შეცდომა');
          })
          .finally(() => {
            setIsAdding(false);
          });
      },
      (errors, event) => {
        console.log(errors, event, 'validation errors');
      },
    )();
  };

  const handleContentChange = (value: string) => {
    setValue('message', value, { shouldValidate: true });
  };

  const handleTypeChange = (value: string) => {
    setValue('type', value, { shouldValidate: true });
  };

  const selectedType = getValues('type');

  const onUserChange = (
    selectedValue: SelectedOptionValue | SelectedOptionValue[],
    selectedOption: SelectedOption | SelectedOption[],
    optionSnapshot: SelectSearchProps,
  ) => {
    setValue('properties', [selectedOption], { shouldValidate: true });
  };

  const onRankChange = (
    selectedValue: SelectedOptionValue | SelectedOptionValue[],
    selectedOption: SelectedOption | SelectedOption[],
    optionSnapshot: SelectSearchProps,
  ) => {
    setValue('properties', selectedOption, { shouldValidate: true });
  };

  return (
    <>
      {isAdding && <OverlayLoader />}
      <>
        <Card className={cn(styles.card)} title="სტატიკური გვერდი" classTitle="title-green">
          <div className={styles.card}>
            <div className={styles.col}>
              <Dropdown
                className={styles.field}
                label="კატეგორია"
                value={selectedType}
                setValue={handleTypeChange}
                options={optionsType}
              />
            </div>
            <br />
            {selectedType === 'personal' && <UserSelector onChange={onUserChange} />}
            {selectedType === 'rank' && <RankSelector onChange={onRankChange} />}

            <br />
            <div className={styles.col}>
              <TextInput
                className={styles.field}
                label="სათაური"
                name="title"
                error={getErrorMessage(errors, 'title')}
                type="text"
                register={register}
              />
            </div>
            <br />

            <div className={styles.col}>
              <TextInput
                className={styles.field}
                label="აღწერა"
                name="description"
                error={getErrorMessage(errors, 'description')}
                type="text"
                register={register}
              />
            </div>
            <br />

            <div className={styles.col}>
              <Editor
                classEditor={styles.editor}
                label="მესიჯი"
                onChange={handleContentChange}
                error={getErrorMessage(errors, 'content')}
              />
            </div>
          </div>
          <div className={cn('panel', styles.panel)}>
            <div className={styles.info}></div>
            <div className={styles.btns}>
              <button onClick={onSubmit} className={cn('button', styles.button)}>
                შენახვა
              </button>
            </div>
          </div>
        </Card>
      </>
    </>
  );
};

export default NotificationAdd;
