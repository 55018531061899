import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAsync } from 'react-async';

import styles from './CustomerList.module.sass';
import Card from '../../../components/Card';
import Form from '../../../components/Form';
import Table from './Table';
import { deleteEvent, getEventList } from 'core/services/komagebi/events';

import Icon from 'components/Icon';
import { paths } from 'router/paths';

const EventListPage = () => {
  const { data: eventResponse, isLoading: isEventListLoading, reload, run } = useAsync({ deferFn: getEventList });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const handleDelete = async (id: string) => {
    const userConfirmed = window.confirm('ნამდვილად გსურთ წაშლა?');
    if (!userConfirmed) {
      return;
    }
    try {
      const result = await deleteEvent(id);
      if (result.data.success) {
        toast.info('ჩანაწერი წაიშალა');
        reload();
        return;
      }
    } catch {
      toast.error('დაფიქსირდა შეცდომა, თავიდან სცადეთ');
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      run(page, search);
    }, 400); // 400ms debounce delay

    // Cleanup timeout if dependencies change before the delay finishes
    return () => clearTimeout(debounceTimeout);
  }, [page, search, run]);

  return (
    <>
      <Card
        className={styles.card}
        title="სია"
        classTitle={cn('title-purple', styles.title)}
        classCardHead={cn(styles.head)}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => {}}
              placeholder="ძებნა"
              type="text"
              name="search"
              icon="search"
            />

            <Link className={cn('button', 'button-small', styles.button)} to={paths.EVENTS_ADD_PATH}>
              <Icon name="add" size="24" />
              <span>დამატება</span>
            </Link>
            {/* <Filters className={styles.filters} title="Showing 10 of 24 customer">
              <Settings />
            </Filters> */}
          </>
        }
      >
        <div className={cn(styles.row)}>
          <Table
            onDelete={id => void handleDelete(id)}
            list={eventResponse}
            isRequestLoading={isEventListLoading}
            className={styles.table}
            pageCount={eventResponse?.data.meta.totalPages ?? 0}
            handlePageChange={(selectedItem: { selected: number }) => {
              setPage(selectedItem.selected + 1);
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default EventListPage;
